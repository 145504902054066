<template>
  <xzd-list-page
    ref="xzdListPage"
    module-name="company"
    :get-list-func="getList"
    :queryParam="queryParam"
    :show-export-excel-button="false"
    :show-import-excel-button="false"
    can-select>
    <div slot="searchBox">
      <company-list-search-box v-model="queryParam" @onSearch="xzdListPageReload"/>
    </div>
    <div slot="buttonsBox" slot-scope="{selectedRows}">
      <save-company-button @xzdListPageReload="xzdListPageReload"/>
      <delete-company-button :ids="selectedRows.map(v=>v.id)" @xzdListPageReload="xzdListPageReload"/>
    </div>
    <template slot="columns">
      <el-table-column prop="id" :label="$t('common.id')" width="100"/>
      <el-table-column prop="name" :label="$t('company.name')"/>
      <el-table-column prop="concat" :label="$t('company.concat')"/>
      <el-table-column prop="mobile" :label="$t('company.mobile')"/>
      <el-table-column prop="maxEmployeeCount" :label="$t('company.maxEmployeeCount')"/>
      <el-table-column prop="employeePageCount" :label="$t('company.employeePageCount')"/>
      <el-table-column :label="$t('common.operate')" width="200" align="center">
        <template slot-scope="scope">
          <save-company-button :id="scope.row.id" @xzdListPageReload="xzdListPageReload"/>
          <delete-company-button :ids="[scope.row.id]" @xzdListPageReload="xzdListPageReload"/>
        </template>
      </el-table-column>
    </template>
  </xzd-list-page>
</template>
<script>
import {list as getList} from "@/api/company";
import CompanyListSearchBox from "./CompanyListSearchBox";
import SaveCompanyButton from "../buttons/SaveCompanyButton";
import DeleteCompanyButton from "../buttons/DeleteCompanyButton";

export default {
  name: "CompanyList",
  components: {DeleteCompanyButton, SaveCompanyButton, CompanyListSearchBox},
  data() {
    return {
      queryParam: {},
      getList
    }
  },
  methods: {
    xzdListPageReload(p = true) {
      this.$refs.xzdListPage.reload(p)
    }
  }
}
</script>